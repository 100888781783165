import { defineStore } from "pinia";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    accessToken: null as string | null,
    fromToken: false,
  }),
  getters: {
    isAuthenticated: async (state) => {
      const { app } = useRuntimeConfig();
      const { backendUrl, backendVersion } = app;
      try {
        const response: Response = await fetch(
          `${backendUrl}/api/${backendVersion}/authentication/session/`,
          {
            credentials: "include",
          },
        );
        const result: { IsAuthenticated: boolean } = await response.json();
        return result.IsAuthenticated;
      } catch (e) {
        return false;
      }
    },
  },
  actions: {
    setToken(token: string | null) {
      this.accessToken = token;
    },
    async setFromToken(state, fromToken: boolean) {
      this.fromToken = fromToken;
    },
    clearToken() {
      this.accessToken = null;
    },
  },
});
